.main {
  display: flex;
  justify-content: center;
  padding-bottom: 45px;
}

.bezos-banner {
  pointer-events: none;
  max-width: 100%;
}

.main-content {
  background-color: #0d1111;
  min-height: 100vh;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.auth-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vmin;
  width: 100%;
}

h1 {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

h2 {
  font-size: 24px;
  text-align: center;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
}

h3 span {
  align-items: center;
  font-weight: 800;
  margin-left: 8px;
}

a {
  color: #fff;
}

.info {
  padding: 0px 10px;
}

.details {
  padding-top: 20px;
}

.bezos-banner {
  background-color: #51b0ff;
  animation: morph 8s ease-in-out infinite;
  box-shadow: -7px 3px 35px -1px rgba(0,0,0,0.75) inset;
  -webkit-box-shadow: -7px 3px 35px -1px rgba(0,0,0,0.75) inset;
  -moz-box-shadow: -7px 3px 35px -1px rgba(0,0,0,0.75) inset;
}

@keyframes morph {
  0% {
    border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
	} 
	
	50% {
    border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
	}
  
	100% {
		border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
	} 
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-input {
  position: relative;
  padding: 10px 0;
}
.form-input:first-of-type {
  padding-top: 0;
}
.form-input:last-of-type {
  padding-bottom: 0;
}
.form-input label {
  transform-origin: left center;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-size: 17px;
  box-sizing: border-box;
  padding: 10px 15px;
  display: block;
  position: absolute;
  margin-top: -40px;
  z-index: 2;
  pointer-events: none;
}
.form-input input {
  appearance: none;
  background-color: none;
  border: 1px solid #515aff;
  line-height: 0;
  font-size: 17px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 60px;
  color: #515aff;
  font-weight: 100;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 1;
}
.form-input input:focus {
  outline: none;
  background: #515aff;
  color: white;
  margin-top: 30px;
}
.form-input input:valid {
  margin-top: 30px;
}
.form-input input:focus ~ label {
  -moz-transform: translate(0, -35px);
  -ms-transform: translate(0, -35px);
  -webkit-transform: translate(0, -35px);
  transform: translate(0, -35px);
}
.form-input input:valid ~ label {
  text-transform: uppercase;
  font-style: italic;
  -moz-transform: translate(5px, -35px) scale(0.6);
  -ms-transform: translate(5px, -35px) scale(0.6);
  -webkit-transform: translate(5px, -35px) scale(0.6);
  transform: translate(5px, -35px) scale(0.6);
}

.transition, button, .form-input label, .form-input input {
  -moz-transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  -o-transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  -webkit-transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
}

button {
  margin-top: 16px;
  background-color: white;
  border: 1px solid #515aff;
  line-height: 0;
  font-size: 17px;
  display: inline-block;
  box-sizing: border-box;
  padding: 20px 15px;
  border-radius: 60px;
  color: #515aff;
  font-weight: 100;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
button:hover, button:focus {
  color: white;
  background-color: #515aff;
}

button.selected {
  color: white;
  background-color: #515aff;
}

.rsvp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.rsvp-container button {
  font-weight: bold;
  font-size: 24px;
  margin-top: 0;
  margin-left: 16px;
}

.calendar-links {
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.calendar-links button {
  margin-left: 8px;
}

